<template>
  <div class="partner-list">
    <!-- <CRow class="mt-2">

     <CCol md="4" v-for="(partner, index) of partners" :key="index">
        <CCard class="partner-card">
          <img class="img-partner" :src="partner.img" />
          <CCardBody class="p-2 position-relative">
            <div>
              <h6 class="text-2-truncate slc-title px-1" :title="partner.name">
                {{ partner.name }}
              </h6>
            </div>
            <div class="partner-footer position-absolute bottom-0">
              <CButton
                variant="ghost"
                size="sm"
                color="info"
                class="px-1 btn-profile"
                :to="`/tfw/partner/${partner.partner_id}/profile`"
                >View Profile</CButton
              >
            </div>
          </CCardBody>
        </CCard>
      </CCol>

      <CCol
        md="4"
        v-for="(partnerPage, index) of getCustomerTemplates"
        :key="index"
      >
        <CCard class="partner-card">
          <img
            class="img-partner"
            :src="getImagePath(partnerPage?.templates)"
          />
          <p class="image-text">{{ title }}</p>
          <CCardBody class="p-2 position-relative">
            <div>
              <h6
                class="text-2-truncate slc-title px-1"
                :title="
                  partnerPage.customer_name || partnerPage.organisation_name
                "
              >
                {{ partnerPage.customer_name || partnerPage.organisation_name }}
              </h6>
            </div>
            <div class="partner-footer position-absolute bottom-0">
              <CButton
                variant="ghost"
                size="sm"
                color="info"
                class="px-1 btn-profile"
                :to="`/tfw/partner/profile/view/${partnerPage.customer_id}/${partnerPage.organisation_id}`"
                >View Profile</CButton
              >
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <p
      v-if="getCustomerTemplates.length === 0"
      class="text-white h4 text-center mt-4"
    >
      No Data Available
    </p> -->

    <div class="mt-3">
      <div class="d-flex justify-content-between mb-3">
        <div>
          <CButton color="primary" class="mr-2 mb-2">Ai SEARCH</CButton>
          <CButton color="primary" class="mr-2 mb-2"
            >MEDICAL FACILITIES</CButton
          >
          <CButton color="primary" class="mr-2 mb-2"
            >CONSULTING SERVICES</CButton
          >
          <CButton color="primary" class="mr-2 mb-2">HEALTH SOLUTIONS</CButton>
          <CButton color="primary" class="mr-2 mb-2"
            >TRAINING PROVIDERS</CButton
          >
        </div>
        <div>
          <CButton color="primary" to="/tfw">Back</CButton>
        </div>
      </div>

      <CRow class="mt-2">
        <CCol
          xs="12"
          sm="6"
          md="4"
          lg="4"
          class="d-flex mb-4"
          v-for="(item, index) of items"
          :key="index"
        >
          <CCard class="overflow-hidden w-100 d-flex flex-column">
            <div class="p-2">
              <h5 class="m-0 font-weight-bold" :title="item.title">
                {{ item.title }}
              </h5>
              <h5 class="m-0" :title="item.title">
                {{ item.sub_title }}
              </h5>
            </div>
            <img class="card-img-top" :src="item.image" style="height: 200px" />
            <CCardBody class="p-2 d-flex flex-column justify-content-between">
              <div>
                <div
                  v-for="(badge, index) in item.badges"
                  :key="index"
                  class="d-inline-flex"
                >
                  <h5>
                    <CBadge
                      class="text-white mx-2 p-2"
                      style="background-color: #5c3cac"
                      >{{ badge }}</CBadge
                    >
                  </h5>
                </div>
              </div>
              <div class="mt-auto text-right">
                <CButton
                  class="btn-primary"
                  :to="`/tfw/partner/view-profile/${item.id}`"
                >
                  View Details
                </CButton>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </div>
  </div>
</template>
<script>
import { CONSTANT } from "@/helpers/helper";
import { mapActions, mapGetters } from "vuex";
import { BASE_URL } from "@/service_urls";
export default {
  data() {
    return {
      partners: CONSTANT.partners,
      title: "",
      items: CONSTANT.partnerProfiles,
    };
  },
  async mounted() {
    this.initAction();
    await this.fetchCustomerTemplates();
  },
  computed: {
    ...mapGetters(["partnersPagesWithOrg", "getCustomerTemplates"]),
    partnersProfilePagesCustom() {
      const res = this.partnersPagesWithOrg.map((v, index) => {
        const partnerObj = v[0];
        const orgObj = v[1];
        return {
          ...partnerObj,
          name: orgObj.name,
          img: orgObj.logo_url
            ? `${BASE_URL}${orgObj.logo_url?.replace("/api/v1", "")}`
            : "/img/new_tf_logo.svg",
        };
      });
      return res;
    },
  },
  methods: {
    ...mapActions(["getPartnersPagesWithOrg", "fetchCustomerTemplates"]),
    initAction() {
      this.getPartnersPagesWithOrg({
        all_rows: true,
        fetch_row_count: false,
        document_type_id: 62, // Profile page Document type id,
        order_by: "-organisation_document_id",
        deleted: false,
      });
    },
    getImagePath(path) {
      console.log("path: ", path);
      const imagePath = path?.[0]?.template_fields?.find(
        (v) => v.field_name === "image_1"
      )?.field_value;
      this.title = path?.[0]?.template_fields?.find(
        (v) => v.field_name === "title"
      )?.field_value;
      return `${BASE_URL}/customer/static/files/download?url=${imagePath}`;
    },
  },
};
</script>
<style lang="scss" scoped>
.text-2-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.slc-title {
  font-weight: 500;
  line-height: 1.235;
  font-size: 1.2rem;
}

.close {
  position: absolute;
  top: 0px;
  right: 28px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

.partner-card {
  min-height: 250px;
  position: relative;
}

.partner-footer {
  bottom: 5px;
}

.img-partner {
  height: 150px;
}

.image-text {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 2.5rem;
  font-weight: bolder;
  z-index: 1;
}
</style>
